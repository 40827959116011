import React, { useState } from "react";
import axios from "axios";
import LoadingPopup from "../LoadingPopup";
import "./addClientUserModal.css";

function AddClientUserModal({ clientId, onClose, onUserAdded }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const validatePhoneNumber = (number) => {
        const cleaned = number.replace(/[\s-]/g, ""); // Remove spaces and dashes
        return /^\+?[0-9]{7,15}$/.test(cleaned); // Accepts any country code, 7-15 digits
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!firstName || !lastName || !email || !phoneNumber) {
            setFeedbackMessage("All fields are required.");
            setShowPopup(true);
            return;
        }

        if (!validatePhoneNumber(phoneNumber)) {
            setFeedbackMessage("Invalid phone number format. Enter a valid number with or without country code. (Format: +971501234567)");
            setShowPopup(true);
            return;
        }

        setIsProcessing(true);
        setFeedbackMessage("");
        setShowPopup(false);

        try {
            const response = await axios.post(`/api/users/clients/${clientId}/`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone_number: phoneNumber.replace(/[\s-]/g, "") // Send cleaned phone number
            });

            setFeedbackMessage("User added successfully.");
            onUserAdded(response.data.user); // Refresh user list
            setShowPopup(true);
        } catch (error) {
            console.error("Error adding user:", error);
            if (error.response && error.response.status === 400 && error.response.data.error === "Email is already in use") {
                setFeedbackMessage("Email is already in use. Please use a different email.");
            } else {
                setFeedbackMessage("Failed to add user. " + error.message);
            }
            setShowPopup(true);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        if (feedbackMessage === "User added successfully.") {
            onClose();
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="popup-close" onClick={onClose}>&times;</span>
                <h3>Add New Client User</h3>
                <form onSubmit={handleSubmit}>
                    <label>First Name</label>
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter first name"
                        required
                    />

                    <label>Last Name</label>
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter last name"
                        required
                    />

                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                        required
                    />

                    <label>Phone Number</label>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter phone number"
                        required
                    />

                    <div className="add-user-modal-actions">
                        <button type="submit" className="save-button">Add User</button>
                    </div>
                </form>
            </div>
            {showPopup && (
                <LoadingPopup
                    isLoading={isProcessing}
                    feedbackMessage={feedbackMessage}
                    onClose={handleClosePopup}
                />
            )}
        </div>
    );
}

export default AddClientUserModal;
