import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './addBooking.css';
import LoadingPopup from '../LoadingPopup';
import axios from 'axios';

import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);

function EditBooking({ bookingId, onClose }) {
  const [cleaners, setCleaners] = useState([]);
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [unavailableCleaners, setUnavailableCleaners] = useState([]);
  const [addons, setAddons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');


  const [formData, setFormData] = useState({
    booking_start: null,
    client_id: '',
    property_id: '',
    type_id: '',
    status_id: '',
    booking_specific_notes: '',
    booking_warehouse_notes: '',
    required_cleaners: 1,
    duration: 2,
    updated_by: 1,
    cleaner_ids: [],
    is_laundry: false, // New field for laundry toggle
    is_amenities: false,
    selected_addons: []
  });

  const navigate = useNavigate();
  const isLaundryOrDelivery = formData.type_id === 6 || formData.type_id === 7 || formData.type_id === 8; // Adjust to your ID for "Delivery / Pickup"
  const laundryRelatedTypes = [1, 5, 6]; // Adjust these based on specific IDs
  const amenitiesRelatedTypes = [1, 5]; // Adjust these based on specific IDs

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (bookingId) {
          const bookingRes = await axios.get(`/api/bookings/details/${bookingId}`);
          const bookingData = bookingRes.data;

          // Fetch assigned and available add-ons in one API call
          const addonsRes = await axios.get(`/api/bookings/addons/${bookingId}`);

          setFormData((prevFormData) => ({
            ...prevFormData,
            client_id: bookingData.client_id || '',
            property_id: bookingData.property_id || '',
            type_id: bookingData.type_id || '',
            status_id: bookingData.status_id || '',
            booking_specific_notes: bookingData.booking_specific_notes || '',
            booking_warehouse_notes: bookingData.booking_warehouse_notes || '',
            required_cleaners: bookingData.required_cleaners || 1,
            duration: bookingData.duration || 2,
            cleaner_ids: bookingData.cleaner_ids || [],
            booking_start: bookingData.booking_start ? new Date(bookingData.booking_start) : null,
            booking_end: bookingData.booking_end ? new Date(bookingData.booking_end) : null,
            is_laundry: bookingData.is_laundry || false,
            is_amenities: bookingData.is_amenities || false,
            selected_addons: addonsRes.data.assignedAddons.map((addon) => ({
              id: addon.item_id,
              name: addon.name,
              price: addon.default_price,
              quantity: addon.quantity || 1,
            })),
          }));

          const [
            clientsRes,
            propertiesRes,
            cleanersRes,
            bookingTypesRes,
            bookingStatusRes,
          ] = await Promise.all([
            axios.get('/api/clients'),
            axios.get(`/api/properties?clientId=${bookingData.client_id}`),
            axios.get('/api/users/role/?role=cleaner'),
            axios.get('/api/bookings/booking_types'),
            axios.get('/api/bookings/booking_status'),
          ]);

          setClients(clientsRes.data);
          setProperties(propertiesRes.data);
          setCleaners(cleanersRes.data);
          setBookingTypes(bookingTypesRes.data);
          setBookingStatus(bookingStatusRes.data);
          setAddons(addonsRes.data.allAddons); // Store available add-ons
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [bookingId]);

  useEffect(() => {
    const fetchUnavailableCleaners = async () => {
      if (!formData.booking_start || !formData.booking_end) return;

      try {
        const response = await axios.get('/api/bookings/cleaners/availability', {
          params: {
            booking_start: formatDateToString(new Date(formData.booking_start)),
            booking_end: formatDateToString(new Date(formData.booking_end)),
            selected_booking_id: bookingId, // Pass the current booking ID
          },
        });
        setUnavailableCleaners(response.data.map(cleaner => cleaner.cleaner_id));
      } catch (error) {
        console.error('Error fetching cleaner availability:', error);
      }
    };

    fetchUnavailableCleaners();
  }, [formData.booking_start, formData.booking_end, bookingId]); // Ensure it re-fetches when bookingId changes



  const handleBookingTypeChange = (selectedOption) => {
    const typeId = selectedOption ? selectedOption.value : '';
    const isLaundryRelated = laundryRelatedTypes.includes(typeId);
    const isAmenitiesRelated = amenitiesRelatedTypes.includes(typeId);

    setFormData({
      ...formData,
      type_id: typeId,
      required_cleaners: typeId === 6 || typeId === 7 ? 0 : Math.max(1, formData.required_cleaners),
      cleaner_ids: typeId === 6 || typeId === 7 ? [] : formData.cleaner_ids,
      duration: typeId === 6 || typeId === 7 ? 0 : formData.duration,
      is_laundry: isLaundryRelated ? true : formData.is_laundry,
      is_amenities: isAmenitiesRelated ? true : formData.is_amenities
    });
  };

  const handleLaundryToggle = () => {
    setFormData({ ...formData, is_laundry: !formData.is_laundry });
  };

  const handleAmenitiesToggle = () => {
    setFormData({ ...formData, is_amenities: !formData.is_amenities });
  };


  const calculateEndTime = (startTime, duration) => {
    if (!startTime) return null;
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(startDateTime.getTime() + duration * 60 * 60 * 1000);
    return endDateTime;
  };

  const handleDateChange = (date) => {
    const bookingStart = isLaundryOrDelivery ? new Date(date).setHours(18, 0, 0, 0) : date;
    const endTime = isLaundryOrDelivery ? bookingStart : calculateEndTime(date, formData.duration);
    setFormData({ ...formData, booking_start: bookingStart, booking_end: endTime });
  };

  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.booking_start) {
      alert('Please select a booking start date and time.');
      return;
    }

    try {
      setIsLoading(true);
      const bookingStartString = formatDateToString(new Date(formData.booking_start));
      const bookingEndString = formData.booking_end
        ? formatDateToString(new Date(formData.booking_end))
        : null;

      const submissionData = {
        ...formData,
        required_cleaners: isLaundryOrDelivery ? 0 : formData.required_cleaners,
        cleaner_ids: formData.cleaner_ids.filter((id) => id),
        booking_start: bookingStartString,
        booking_end: bookingEndString,
        selected_addons: formData.selected_addons.map((addon) => ({
          item_id: addon.id,
          quantity: addon.quantity,
        })),
      };

      await axios.put(`/api/bookings/${bookingId}`, submissionData);
      setFeedbackMessage('Booking updated successfully!');
    } catch (error) {
      console.error('Error updating booking:', error);
      setFeedbackMessage('Error updating booking.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : '' });
  };

  const handleCleanerChange = (selectedOption, index) => {
    const newCleanerIds = [...formData.cleaner_ids];
    newCleanerIds[index] = selectedOption ? selectedOption.value : null;
    setFormData({ ...formData, cleaner_ids: newCleanerIds });
  };

  const handleDurationChange = (selectedOption) => {
    const duration = parseFloat(selectedOption.value);
    const endTime = formData.booking_start ? calculateEndTime(formData.booking_start, duration) : null;
    setFormData({ ...formData, duration, booking_end: endTime });
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this booking? This action cannot be undone.')) {
      try {
        setIsLoading(true);
        await axios.delete(`/api/bookings/${bookingId}`);
        setFeedbackMessage('Booking deleted successfully!');
      } catch (error) {
        console.error('Error deleting booking:', error);
        setFeedbackMessage('Error deleting booking.');
      } finally {
        setIsLoading(false);
      }
    }
  };


  const durationOptions = [
    { value: '1', label: "1 Hour" },
    { value: '1.5', label: "1.5 Hours" },
    { value: '2', label: '2 Hours' },
    { value: '2.5', label: '2.5 Hours' },
    { value: '3', label: '3 Hours' },
    { value: '3.5', label: '3.5 Hours' },
    { value: '4', label: '4 Hours' },
    { value: '4.5', label: '4.5 Hours' },
    { value: '5', label: '5 Hours' },
    { value: '5.5', label: '5.5 Hours' },
    { value: '6', label: '6 Hours' },
    { value: '6.5', label: '6.5 Hours' },
    { value: '7', label: '7 Hours' },
    { value: '7.5', label: '7.5 Hours' },
    { value: '8', label: '8 Hours' },
  ];

  const cleanerSelects = [];
  for (let i = 0; i < formData.required_cleaners; i++) {
    cleanerSelects.push(
      <div key={i} className="form-group">
        <label>Cleaner {i + 1}</label>
        <Select
          options={cleaners.map((cleaner) => ({
            value: cleaner.id,
            label: `${cleaner.first_name} ${cleaner.last_name} ${unavailableCleaners.includes(cleaner.id) &&
              !formData.cleaner_ids.includes(cleaner.id) // Exclude assigned cleaners
              ? '(Unavailable)'
              : ''
              }`,
            isDisabled:
              unavailableCleaners.includes(cleaner.id) &&
              !formData.cleaner_ids.includes(cleaner.id), // Enable assigned cleaners
          }))}
          value={cleaners
            .map((cleaner) => ({
              value: cleaner.id,
              label: `${cleaner.first_name} ${cleaner.last_name} ${unavailableCleaners.includes(cleaner.id) &&
                !formData.cleaner_ids.includes(cleaner.id)
                ? '(Unavailable)'
                : ''
                }`,
            }))
            .find(option => option.value === formData.cleaner_ids[i]) || null}
          onChange={(selectedOption) => handleCleanerChange(selectedOption, i)}
          placeholder={`Select Cleaner ${i + 1}`}
          isClearable
        />
      </div>
    );
  }

  return (
    <div className="popup-overlay">
      <div className="edit-booking-popup">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <h2>Edit Booking</h2>
        <form className="new-booking-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>Booking Type</label>
              <Select
                options={bookingTypes.map((type) => ({
                  value: type.id,
                  label: type.type,
                }))}
                value={bookingTypes
                  .map((type) => ({
                    value: type.id,
                    label: type.type,
                  }))
                  .find((option) => option.value === formData.type_id) || null}
                onChange={handleBookingTypeChange}
                placeholder="Select a booking type"
                required
              />
            </div>
            <div className="form-group">
              <label>Booking Status</label>
              <Select
                options={bookingStatus.map((status) => ({
                  value: status.id,
                  label: status.status_name,
                }))}
                value={bookingStatus
                  .map((status) => ({
                    value: status.id,
                    label: status.status_name,
                  }))
                  .find((option) => option.value === formData.status_id) || null}
                onChange={(selectedOption) => handleSelectChange(selectedOption, 'status_id')}
                placeholder="Select booking status"
                required
              />
            </div>
            <div className="form-group laundry">
              <label>Is Laundry?</label>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formData.is_laundry}
                  onChange={handleLaundryToggle}
                  disabled={false} // Allow editing even if the type is automatically set
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group laundry">
              <label>Is Amenities?</label>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formData.is_amenities}
                  onChange={handleAmenitiesToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Client</label>
              <Select
                options={clients.map((client) => ({
                  value: client.id,
                  label: client.display_name || `${client.first_name} ${client.last_name}`,
                }))}
                value={clients
                  .map((client) => ({
                    value: client.id,
                    label: client.display_name || `${client.first_name} ${client.last_name}`,
                  }))
                  .find((option) => option.value === formData.client_id) || null}
                placeholder="Select a client"
                isDisabled // Make this field non-editable as per requirements
              />
            </div>
            <div className="form-group">
              <label>Property</label>
              <Select
                options={properties.map((property) => ({
                  value: property.id,
                  label: `${property.unit_number} - ${property.address_line_1} (${property.bedrooms === 0 ? 'S' : property.bedrooms}, ${property.bathrooms})`,
                }))}
                value={properties
                  .map((property) => ({
                    value: property.id,
                    label: `${property.unit_number} - ${property.address_line_1} (${property.bedrooms === 0 ? 'S' : property.bedrooms}, ${property.bathrooms})`,
                  }))
                  .find((option) => option.value === formData.property_id) || null}
                placeholder="Select a property"
                isDisabled // Make this field non-editable since properties are fixed to a client
                required
              />
            </div>
          </div>
          <div className="form-row">
            {!isLaundryOrDelivery && (
              <div className="form-group">
                <label>Required Cleaners</label>
                <input
                  type="number"
                  name="required_cleaners"
                  value={formData.required_cleaners}
                  onChange={handleChange}
                  min="1"
                  required
                />
              </div>
            )}
            <div className="form-group">
              <label>Start Time</label>
              <DatePicker
                selected={formData.booking_start}
                onChange={handleDateChange}
                showTimeSelect={!isLaundryOrDelivery}
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="Time"
                locale="en-GB"
                dateFormat={isLaundryOrDelivery ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
                placeholderText="Select Start Time"
                required
              />
            </div>
            {!isLaundryOrDelivery && (
              <div className="form-group">
                <label>Duration</label>
                <Select
                  options={durationOptions}
                  value={durationOptions.find(option => option.value === String(formData.duration)) || null}
                  onChange={handleDurationChange}
                  placeholder="Select a duration"
                  required
                />
              </div>
            )}
          </div>
          {!isLaundryOrDelivery && <div className="form-row">{cleanerSelects}</div>}
          <div className="form-group">
            <label>Booking Notes</label>
            <textarea
              name="booking_specific_notes"
              value={formData.booking_specific_notes}
              onChange={handleChange}
              placeholder="Add any specific notes for this booking"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Warehouse Notes</label>
            <textarea
              name="booking_warehouse_notes"
              value={formData.booking_warehouse_notes}
              onChange={handleChange}
              placeholder="Add any specific warehouse notes for this booking"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Add-ons</label>
            <Select
              options={addons.map((addon) => ({
                value: addon.item_id,
                label: addon.name,
                price: addon.default_price,
                isDisabled: formData.selected_addons.some(
                  (selected) => selected.id === addon.item_id
                ), // Disable already selected add-ons
              }))}
              isMulti
              value={formData.selected_addons.map((addon) => ({
                value: addon.id,
                label: addon.name,
                price: addon.price,
              }))}
              onChange={(selectedOptions) => {
                const updatedAddons = selectedOptions
                  ? selectedOptions.map((option) => {
                    // Check if the addon already exists in the current selection
                    const existingAddon = formData.selected_addons.find(
                      (a) => a.id === option.value
                    );

                    return {
                      id: option.value,
                      name: option.label,
                      price: option.price,
                      quantity: existingAddon ? existingAddon.quantity : 1, // Preserve quantity if it exists
                    };
                  })
                  : [];

                setFormData((prevFormData) => ({
                  ...prevFormData,
                  selected_addons: updatedAddons,
                }));
              }}

              placeholder="Select add-ons"
            />

            {formData.selected_addons.length > 0 && (
              <div className="addons-list">
                {formData.selected_addons.map((addon) => (
                  <div key={addon.id} className="addon-item">
                    <div className="addon-details">
                      <span className="addon-name">{addon.name}</span>
                      <span className="addon-unit-price">{`AED ${addon.price}`}</span>
                    </div>
                    <input
                      type="number"
                      step="0.5"
                      min="1"
                      value={addon.quantity}
                      onChange={(e) => {
                        const updatedAddons = formData.selected_addons.map((a) =>
                          a.id === addon.id
                            ? { ...a, quantity: parseFloat(e.target.value) || 1 }
                            : a
                        );
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          selected_addons: updatedAddons,
                        }));
                      }}
                      className="addon-quantity"
                    />
                    <span className="addon-total-price">{`AED ${(
                      addon.price * addon.quantity
                    ).toFixed(2)}`}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <button type="submit" className="submit-button">Update Booking</button>
        </form>

        <button
          type="button"
          className="delete-button"
          onClick={handleDelete}
        >
          Delete Booking
        </button>
      </div>
      <LoadingPopup
        isLoading={isLoading}
        feedbackMessage={feedbackMessage}
        onClose={() => {
          setFeedbackMessage('');
          if (feedbackMessage === 'Booking updated successfully!') {
            onClose(); // Close the popup after success
          } else if (feedbackMessage === 'Booking deleted successfully!') {
            navigate('/bookings');
          }
        }}
      />
    </div>

  );
}

export default EditBooking;