import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from '../LoadingPopup';
import AddClientUserModal from './AddClientUserModal';
import './clientDetails.css';

function ClientDetails() {
    const { clientId } = useParams();
    const [client, setClient] = useState(null);
    const [users, setUsers] = useState([]);
    const [properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUser, setEditedUser] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const response = await axios.get(`/api/clients/${clientId}`);
                if (response.data) {
                    setClient(response.data);
                } else {
                    throw new Error('No client data received');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching client:', error);
                setError('Error fetching client data');
                setLoading(false);
            }
        };

        fetchClient();
    }, [clientId]);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await axios.get(`/api/properties?clientId=${clientId}`);
                setProperties(response.data);
            } catch (error) {
                console.error('Error fetching properties:', error);
                setError('Error fetching properties data');
            }
        };

        fetchProperties();
    }, [clientId]);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.get(`/api/clients/${clientId}/users`);
            if (response.data && Array.isArray(response.data.users)) {
                setUsers(response.data.users);
            } else {
                setUsers([]);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users data');
            setUsers([]);
        }
    }, [clientId]);
    
    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    // Filter properties by building name, unit number, or property ID
    const filteredProperties = Array.isArray(properties)
        ? properties.filter(property => {
            const addressLine1 = property.address_line_1 || '';
            const unitNumber = property.unit_number || '';
            const propertyId = property.id ? property.id.toString() : '';
            const formattedPropertyId = `#${propertyId.padStart(5, '0')}`;
            return (
                addressLine1.toLowerCase().includes(searchTerm.toLowerCase()) ||
                unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
                propertyId.includes(searchTerm) ||
                formattedPropertyId.includes(searchTerm)
            );
        })
        : [];

    // Format ID to be 5 digits
    const formatId = (id) => {
        if (!id) return 'N/A'; // Handle null or undefined IDs
        return `#${id.toString().padStart(5, '0')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const trimmedDate = dateString.split('.')[0];
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(trimmedDate).toLocaleDateString(undefined, options);
    };

    const handleEditClick = () => {
        navigate(`/clients/edit/${clientId}`);
    };

    const handleRowClick = (propertyId) => {
        navigate(`/properties/${propertyId}`);
    };

    const handleEditUserClick = (user) => {
        if (editingUserId === user.id) {
            setEditingUserId(null);
            setEditedUser({});
        } else {
            setEditingUserId(user.id);
            setEditedUser({ ...user });
        }
    };

    const handleSaveClick = async (userId) => {
        if (!window.confirm("Are you sure you want to edit this user?")) return;

        setIsProcessing(true); // Start loading
        setFeedbackMessage(""); // Clear old messages

        try {
            await axios.put(`/api/users/clients/${userId}`, editedUser);
            setUsers(users.map(user => user.id === userId ? { ...user, ...editedUser } : user)); // Merge changes
            setEditingUserId(null);
            setEditedUser({});
            setFeedbackMessage("User updated successfully.");
        } catch (error) {
            console.error('Error updating user:', error);
            setFeedbackMessage("Failed to update user.");
        } finally {
            setIsProcessing(false); // Stop loading
        }
    };

    const handleDeleteClick = async (userId) => {
        if (!window.confirm("Are you sure you want to delete this user?")) return;

        setIsProcessing(true);
        setFeedbackMessage("");

        try {
            await axios.delete(`/api/users/clients/${userId}`);
            setUsers(users.filter(user => user.id !== userId));
            setFeedbackMessage("User deleted successfully.");
        } catch (error) {
            console.error('Error deleting user:', error);
            setFeedbackMessage("Failed to delete user.");
        } finally {
            setIsProcessing(false);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="client-details-container">
            <div className="header-section">
                <h2>Client Details</h2>
                <button onClick={handleEditClick} className="edit-button">Edit Client</button>
            </div>

            {client ? (
                <div className="client-info">
                    <div className="client-info-row">
                        <span><strong>ID:</strong> {formatId(client.id)}</span>
                        <span><strong>First Name:</strong> {client.first_name}</span>
                        <span><strong>Last Name:</strong> {client.last_name}</span>
                    </div>
                    <div className="client-info-row">
                        <span><strong>Email:</strong> {client.email}</span>
                        <span><strong>Company Name:</strong> {client.company_name}</span>
                        <span><strong>Display Name:</strong> {client.display_name}</span>
                    </div>
                    <div className="client-info-row">
                        <span><strong>Contact Number:</strong> {client.contact_number}</span>
                        <span>
                            <strong>Zoho Books:</strong>{' '}
                            <a
                                href={`https://books.zoho.eu/app/20097730526#/contacts/${client.zoho_contact_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {client.zoho_contact_id}
                            </a>
                        </span>
                        <span><strong>Registration Date:</strong> {formatDate(client.registration)}</span>
                    </div>
                </div>
            ) : (
                <p className="error-message">Client data is unavailable.</p>
            )}

            <div className="properties-section">
                <div className='properties-section-header'>
                    <h3 className='client-details'>Properties</h3>
                    <Link to={`/properties/new?clientId=${clientId}`}>
                        <button className="add-property-button">+ Add New Property</button>
                    </Link>
                </div>
                <div className="search-and-add-container">
                    <input
                        type="text"
                        placeholder="Search by Building Name, Unit Number, or Property ID..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="search-bar"
                    />
                </div>

                {filteredProperties.length > 0 ? (
                    <table className="properties-table">
                        <thead>
                            <tr>
                                <th>Property ID</th>
                                <th>Building Name</th>
                                <th style={{ textAlign: 'center' }}>Unit Number</th>
                                <th style={{ textAlign: 'center' }}>Bedrooms</th>
                                <th style={{ textAlign: 'center' }}>Area</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProperties.map((property) => (
                                <tr key={property.id} onClick={() => handleRowClick(property.id)} style={{ cursor: 'pointer' }}>
                                    <td>{formatId(property.id)}</td>
                                    <td>{property.address_line_1}</td>
                                    <td style={{ textAlign: 'center' }}>{property.unit_number}</td>
                                    <td style={{ textAlign: 'center' }}>{property.bedrooms}</td>
                                    <td style={{ textAlign: 'center' }}>{property.area}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="no-properties">No properties found for this client.</p>
                )}
            </div>

            <div className="users-section">
                <div className="users-section-header">
                    <h3>Users</h3>
                    <button className="add-new-client-user" onClick={() => setIsModalOpen(true)}>+ Add New User</button>
                </div>

                {users.length > 0 ? (
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>{formatId(user.id)}</td>

                                    <td>
                                        {editingUserId === user.id ? (
                                            <input
                                                type="text"
                                                value={editedUser.first_name || ''}
                                                onChange={(e) => setEditedUser({ ...editedUser, first_name: e.target.value })}
                                            />
                                        ) : (
                                            user.first_name
                                        )}
                                    </td>

                                    <td>
                                        {editingUserId === user.id ? (
                                            <input
                                                type="text"
                                                value={editedUser.last_name || ''}
                                                onChange={(e) => setEditedUser({ ...editedUser, last_name: e.target.value })}
                                            />
                                        ) : (
                                            user.last_name
                                        )}
                                    </td>

                                    <td>
                                        {editingUserId === user.id ? (
                                            <input
                                                type="text"
                                                value={editedUser.email || ''}
                                                onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                                            />
                                        ) : (
                                            user.email
                                        )}
                                    </td>

                                    <td>
                                        {editingUserId === user.id ? (
                                            <input
                                                type="text"
                                                value={editedUser.phone_number || ''}
                                                onChange={(e) => setEditedUser({ ...editedUser, phone_number: e.target.value })}
                                            />
                                        ) : (
                                            user.phone_number
                                        )}
                                    </td>

                                    <td style={{ textAlign: 'right' }}>
                                        {editingUserId === user.id ? (
                                            <>
                                                <FontAwesomeIcon icon={faTrash} className="user-edit-icon user-delete-icon" onClick={() => handleDeleteClick(user.id)} />
                                                <FontAwesomeIcon icon={faCheck} className="user-edit-icon user-save-icon" onClick={() => handleSaveClick(user.id)} />
                                                <FontAwesomeIcon icon={faTimes} className="user-edit-icon user-cancel-icon" onClick={() => handleEditUserClick(user)} />
                                            </>
                                        ) : (
                                            <FontAwesomeIcon icon={faPen} className="user-edit-icon edit-user-icon" onClick={() => handleEditUserClick(user)} />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="no-users">No users found for this client.</p>
                )}
            </div>
            <LoadingPopup
                isLoading={isProcessing}
                feedbackMessage={feedbackMessage}
                onClose={() => setFeedbackMessage("")}
            />
            {isModalOpen && (
                <AddClientUserModal clientId={clientId} onClose={() => setIsModalOpen(false)} onUserAdded={fetchUsers} />
            )}
        </div>
    );
}

export default ClientDetails;
