import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './warehouse.css';

function Warehouse() {
    const [selectedDate, setSelectedDate] = useState('');
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const fetchReport = useCallback(async (date) => {
        if (!date) return;
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`/api/bookings/daily-report/${date}`);
            setReport(response.data.bookings);
            navigate(`?date=${date}`, { replace: true });
        } catch (error) {
            console.error('Error fetching warehouse report:', error);
            setError('Failed to load report. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const date = params.get('date');
        if (date) {
            setSelectedDate(date);
            fetchReport(date);
        }
    }, [location.search, fetchReport]);

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const getAreaColor = (area) => {
        switch (area) {
            case 'Downtown Dubai':
            case 'Business Bay':
                return '#4c7ac5'; // Blue
            case 'Marina':
            case 'JLT':
            case 'JBR':
                return '#ae2021'; // Red
            case 'Palm Jumeirah':
                return '#d9b342'; // Gold
            case 'Creek Harbour':
                return '#476f37'; // Green
            case 'Azizi Riviera':
                return '#a43777'; // Magenta
            default:
                return '#6c757d'; // Grey
        }
    };

    const calculateLinen = (singleBeds, doubleBeds, bathrooms) => {
        const roundedBathrooms = Math.ceil(bathrooms);
        return {
            BS: doubleBeds > 0 ? `${doubleBeds} BS` : null,
            smallBS: singleBeds > 0 ? `${singleBeds} SMALL BS` : null,
            DC: doubleBeds > 0 ? `${doubleBeds} DC` : null,
            smallDC: singleBeds > 0 ? `${singleBeds} SMALL DC` : null,
            PC: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds * 2 + doubleBeds * 4} PC` : null,
            BT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} BT` : null,
            HT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} HT` : null,
            FT: singleBeds > 0 || doubleBeds > 0 ? `${singleBeds + doubleBeds * 2} FT` : null,
            BM: roundedBathrooms > 0 ? `${roundedBathrooms} BM` : null,
        };
    };

    const itemShortNames = {
        'Small Bedsheet' : "SMALL BS",
        'Small Duvet Cover' : "SMALL DC",
        'Bedsheet': 'BS',
        'Duvet Cover': 'DC',
        'Pillowcase': 'PC',
        'Bath Towel': 'BT',
        'Hand Towel': 'HT',
        'Face Towel': 'FT',
        'Bathmat': 'BM',
        'Beach Towel': 'BEACH'
    };

    const orderedItemShortNames = [
        'Bedsheet',
        'Small Bedsheet',
        'Duvet Cover',
        'Small Duvet Cover',
        'Pillowcase',
        'Bath Towel',
        'Hand Towel',
        'Face Towel',
        'Bathmat',
        'Beach Towel'
    ];

    const getDutyInfo = (booking) => {
        let dutyText = '';
        let linenText = '';
        let laundryText = '';
        let laundryStatusText = '';
        const now = new Date(booking.booking_start).getTime();
        const lastBooking = new Date(booking.last_booking_date).getTime();
        const isUrgent = (now - lastBooking) <= 60 * 60 * 1000 * 60; // 60 hours in milliseconds

        if (!booking.is_laundry && !booking.is_amenities) {
            return { duty: <span style={{ backgroundColor: 'red' }}>No preparation required</span> };
        }

        if (booking.is_laundry) {
            if (booking.is_linen_hire) {
                dutyText = <span style={{ backgroundColor: 'yellow' }}>Pack Linen Hire</span>;
                const linenCounts = calculateLinen(booking.single_beds, booking.double_beds, booking.bathrooms);
                linenText = Object.values(linenCounts).filter(Boolean).join(', ');
            } else {
                dutyText = 'Pack Laundry';
            }
        }

        if (booking.client_name === "Mai De Luxe" && booking.standard_items?.length) {
            const sortedItems = orderedItemShortNames
                .map(name => booking.standard_items.find(item => item.item_name === name))
                .filter(Boolean);

            laundryText = (
                <span>
                    <span style={{ backgroundColor: 'silver' }}>Prepare From MDL:</span>{' '}
                    {sortedItems
                        .map(item => `${item.standard_quantity} ${itemShortNames[item.item_name]}`)
                        .join(', ')}
                </span>
            );
        } else if (!booking.is_linen_hire) {
            const laundryStatusStyle = {
                color: booking.laundry_status === 'completed' ? 'inherit' : 'red',
            };

            laundryStatusText = booking.laundry_status
                ? (
                    <Link to={`/laundry/${booking.laundry_count_id}`} className='laundry-link'>
                        Laundry Status: <span style={laundryStatusStyle}>{booking.laundry_status}</span>, Packed in {booking.laundry_bags} bags
                        {isUrgent && <span style={{ color: 'red', marginLeft: '5px' }}>URGENT</span>}
                    </Link>
                )
                : (
                    <Link to={`/laundry/${booking.id}`} style={{ color: 'red' }}>
                        Laundry Status: Not Confirmed
                    </Link>
                );
        }

        if (booking.booking_type === 'Pickup') {
            dutyText = 'Prepare empty blue bag with tag';
        } else if (booking.booking_type === 'Dropoff') {
            dutyText = 'Prepare Linens';
        }

        const amenitiesText = booking.is_amenities ? (
            'Pack Amenities'
        ) : (
            <span style={{ backgroundColor: 'red' }}>
                NO AMENITIES
            </span>
        );

        return {
            duty: dutyText,
            amenities: amenitiesText,
            linenText,
            laundryText,
            laundryStatus: laundryStatusText,
        };
    };

    const sortReport = (report) => {
        const linenHireBookings = report.filter(booking => booking.is_linen_hire && booking.is_laundry);
        const mdlBookings = report.filter(booking => booking.client_name === "Mai De Luxe" && !booking.is_linen_hire);
        const amenitiesBookings = report.filter(booking => booking.is_amenities && !booking.is_linen_hire && booking.client_name !== "Mai De Luxe");
        const noAmenitiesBookings = report.filter(booking => booking.is_laundry && !booking.is_amenities && !booking.is_linen_hire && booking.client_name !== "Mai De Luxe");
        const noPreparationRequired = report.filter(booking => !booking.is_amenities && !booking.is_laundry);

        return [...linenHireBookings, ...mdlBookings, ...amenitiesBookings, ...noAmenitiesBookings, ...noPreparationRequired];
    };

    const sortedReport = sortReport(report);

    return (
        <div className="warehouse-container">
            <h2>Warehouse Duty Report</h2>
            <div className="date-selector">
                <label>Select Date:</label>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="date-input"
                />
                <button onClick={() => fetchReport(selectedDate)} className="fetch-button">
                    Fetch Report
                </button>
            </div>

            {loading ? (
                <div>Loading report...</div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : (
                <div className="report-output">
                    {sortedReport.length > 0 ? (
                        sortedReport.map((booking, index) => {
                            const dutyInfo = getDutyInfo(booking);
                            const formattedPropertyId = `#${String(booking.property_id).padStart(3, '0')}`;
                            const areaColor = getAreaColor(booking.area);

                            return (
                                <div key={index} className="warehouse-entry">
                                    <Link to={`/bookings/${booking.id}`} className="entry-title" style={{ color: areaColor }}>
                                        {index + 1}. {formattedPropertyId} - {booking.client_name} - {booking.building_name} - Unit {booking.unit_number} ({booking.bedrooms === 0 ? 'S' : booking.bedrooms} - {booking.bathrooms})
                                    </Link>
                                    <p>
                                        {dutyInfo.duty}
                                        {dutyInfo.amenities && <span> + {dutyInfo.amenities}</span>}
                                    </p>
                                    {dutyInfo.linenText && <p>{dutyInfo.linenText}</p>}
                                    {dutyInfo.laundryText && <p>{dutyInfo.laundryText}</p>}
                                    {dutyInfo.laundryStatus && <p>{dutyInfo.laundryStatus}</p>}
                                    {(booking.warehouse_note || booking.booking_warehouse_notes) && (
                                        <p>Notes: {booking.warehouse_note} {booking.booking_warehouse_notes}</p>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No report available for the selected date.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Warehouse;