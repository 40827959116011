import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios'; // Import Axios
import './addProperty.css';
import LoadingPopup from '../LoadingPopup'; // Import LoadingPopup component

const areaOptions = [
    { value: "Downtown Dubai", label: "Downtown Dubai" },
    { value: "Business Bay", label: "Business Bay" },
    { value: "Marina", label: "Marina" },
    { value: "JLT", label: "JLT" },
    { value: "JBR", label: "JBR" },
    { value: "Palm Jumeirah", label: "Palm Jumeirah" },
    { value: "Creek Harbour", label: "Creek Harbour" },
    { value: "Azizi Riviera", label: "Azizi Riviera" },
    { value: "JVC", label: "JVC" },
    { value: "Other Area", label: "Other Area" }
];

const bedroomOptions = [
    { value: 0, label: "Studio" },
    { value: 1, label: "1 Bedroom" },
    { value: 2, label: "2 Bedrooms" },
    { value: 3, label: "3 Bedrooms" },
    { value: 4, label: "4 Bedrooms" },
    { value: 5, label: "5 Bedrooms" },
    { value: 6, label: "6 Bedrooms" }
];

const cleanerOptions = [
    { value: 1, label: "1 Cleaner" },
    { value: 2, label: "2 Cleaners" },
    { value: 3, label: "3 Cleaners" },
    { value: 4, label: "4 Cleaners" }
];

const bathroomOptions = [
    { value: 1, label: "1 Bathroom" },
    { value: 1.5, label: "1.5 Bathrooms" },
    { value: 2, label: "2 Bathrooms" },
    { value: 2.5, label: "2.5 Bathrooms" },
    { value: 3, label: "3 Bathrooms" },
    { value: 3.5, label: "3.5 Bathrooms" },
    { value: 4, label: "4 Bathrooms" },
    { value: 4.5, label: "4.5 Bathrooms" },
    { value: 5, label: "5 Bathrooms" },
    { value: 5.5, label: "5.5 Bathrooms" },
    { value: 6, label: "6 Bathrooms" },
    { value: 6.5, label: "6.5 Bathrooms" }
];

const bedCountOptions = [
    { value: 0, label: "0 Bed" },
    { value: 1, label: "1 Bed" },
    { value: 2, label: "2 Beds" },
    { value: 3, label: "3 Beds" },
    { value: 4, label: "4 Beds" },
    { value: 5, label: "5 Beds" },
    { value: 6, label: "6 Beds" },
    { value: 7, label: "7 Beds" },
    { value: 8, label: "8 Beds" }
];

function AddProperty() {
    const [clients, setClients] = useState([]);
    const [newProperty, setNewProperty] = useState({
        client_id: '',
        address_line_1: '',
        unit_number: '',
        bedrooms: '',
        bathrooms: '',
        area: '',
        google_maps_link: '',
        is_linen_hire: false,
        required_cleaners: 1, // Default cleaner count
        permanent_notes: '',
        property_access: '',
        wifi_name: '',
        wifi_password: '',
        single_beds: 0, // Default to 0
        double_beds: 1,  // Default to 1
        is_custom_price: false, // New toggle for custom price
        custom_price: '' // New custom price field
    });

    const [isLoading, setIsLoading] = useState(false); // State for loading spinner
    const [feedbackMessage, setFeedbackMessage] = useState(''); // State for feedback message

    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get('/api/clients');
                const clientOptions = response.data.map(client => ({
                    value: client.id,
                    label: client.display_name || `${client.first_name} ${client.last_name}`
                }));
                setClients(clientOptions);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    const handleAddProperty = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true); // Show loading popup
            const payload = {
                ...newProperty,
                custom_price: newProperty.is_custom_price ? newProperty.custom_price : null, // Set custom_price to null if not toggled
            };
            const response = await axios.post('/api/properties', payload);
            if (response.status === 201) {
                setFeedbackMessage('Property added successfully!');
                setTimeout(() => {
                    navigate('/properties'); // Redirect after success
                }, 1500);
            } else {
                console.error('Error adding property');
                setFeedbackMessage('Failed to add property. Please try again.');
            }
        } catch (error) {
            console.error('Error adding property:', error);
            setFeedbackMessage('Failed to add property. Please try again.');
        } finally {
            setIsLoading(false); // Hide loading popup
        }
    };

    const handleClosePopup = () => {
        setFeedbackMessage(''); // Clear feedback message
    };

    const handleBedroomChange = (selectedOption) => {
        const selectedBedrooms = selectedOption.value;
        let doubleBeds = 1; // Default to 1 double bed, including for Studio
        let cleaners = 1; // Default 1 cleaner

        // Adjust double beds and cleaners based on bedroom count
        if (selectedBedrooms === 2) {
            doubleBeds = 2;
        } else if (selectedBedrooms >= 3) {
            doubleBeds = selectedBedrooms; // 3 or more bedrooms
            cleaners = 2; // For 3 or more bedrooms, 2 cleaners
        }

        setNewProperty({
            ...newProperty,
            bedrooms: selectedBedrooms,
            double_beds: doubleBeds,
            required_cleaners: cleaners
        });
    };

    return (
        <div className="add-property-container">
            <LoadingPopup
                isLoading={isLoading}
                feedbackMessage={feedbackMessage}
                onClose={handleClosePopup}
            />
            <h2>Add New Property</h2>
            <form className="new-property-form" onSubmit={handleAddProperty}>
                <div className="form-section">
                    <h3>Select Client</h3>
                    <Select
                        options={clients}
                        onChange={(selectedOption) => setNewProperty({ ...newProperty, client_id: selectedOption.value })}
                        placeholder="Search and select a client by display name..."
                        isSearchable
                        required
                    />
                </div>

                <div className="form-section">
                    <h3>Property Details</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Building Name</label>
                            <input
                                type="text"
                                value={newProperty.address_line_1}
                                onChange={(e) => setNewProperty({ ...newProperty, address_line_1: e.target.value })}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Unit Number</label>
                            <input
                                type="text"
                                value={newProperty.unit_number}
                                onChange={(e) => setNewProperty({ ...newProperty, unit_number: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Area</label>
                            <Select
                                options={areaOptions}
                                onChange={(selectedOption) => setNewProperty({ ...newProperty, area: selectedOption.value })}
                                placeholder="Select an area"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Google Maps Link</label>
                            <input
                                type="text"
                                value={newProperty.google_maps_link}
                                onChange={(e) => setNewProperty({ ...newProperty, google_maps_link: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Bedrooms</label>
                            <Select
                                options={bedroomOptions}
                                onChange={handleBedroomChange}
                                placeholder="Select"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Bathrooms</label>
                            <Select
                                options={bathroomOptions}
                                onChange={(selectedOption) => setNewProperty({ ...newProperty, bathrooms: selectedOption.value })}
                                placeholder="Select"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Bed Configuration</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Single Beds</label>
                            <Select
                                options={bedCountOptions}
                                value={bedCountOptions.find(option => option.value === newProperty.single_beds)}
                                onChange={(selectedOption) => setNewProperty({ ...newProperty, single_beds: selectedOption.value })}
                                placeholder="Select Single Beds"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Double Beds</label>
                            <Select
                                options={bedCountOptions}
                                value={bedCountOptions.find(option => option.value === newProperty.double_beds)}
                                onChange={(selectedOption) => setNewProperty({ ...newProperty, double_beds: selectedOption.value })}
                                placeholder="Select Double Beds"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Additional Info</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Is Linen Hire?</label>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={newProperty.is_linen_hire}
                                    onChange={(e) => setNewProperty({ ...newProperty, is_linen_hire: e.target.checked })}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Standard Staffing</label>
                            <Select
                                options={cleanerOptions}
                                value={cleanerOptions.find(option => option.value === newProperty.required_cleaners)}
                                onChange={(selectedOption) => setNewProperty({ ...newProperty, required_cleaners: selectedOption.value })}
                                placeholder="Select"
                                required
                            />
                        </div>
                    </div>

                    {/* New toggle for custom price */}
                    <div className="form-row">
                        <div className="form-group">
                            <label>Is Custom Price?</label>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={newProperty.is_custom_price}
                                    onChange={(e) => setNewProperty({ ...newProperty, is_custom_price: e.target.checked })}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>

                        {/* Conditionally render custom price input */}
                        {newProperty.is_custom_price && (
                            <div className="form-group">
                                <label>Custom Price</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={newProperty.custom_price}
                                    onChange={(e) => setNewProperty({ ...newProperty, custom_price: e.target.value })}
                                    placeholder="Enter custom price"
                                    required
                                    onWheel={(e) => e.target.blur()} // Prevent scrolling
                                    onKeyDown={(e) => e.key === "ArrowUp" || e.key === "ArrowDown" ? e.preventDefault() : null} // Prevent arrow keys
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label>Permanant Notes</label>
                    <textarea
                        value={newProperty.permanent_notes}
                        onChange={(e) => setNewProperty({ ...newProperty, permanent_notes: e.target.value })}
                    />
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Property Access Info</label>
                        <input
                            type="text"
                            value={newProperty.property_access}
                            onChange={(e) => setNewProperty({ ...newProperty, property_access: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label>Wi-Fi Name</label>
                        <input
                            type="text"
                            value={newProperty.wifi_name}
                            onChange={(e) => setNewProperty({ ...newProperty, wifi_name: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label>Wi-Fi Password</label>
                        <input
                            type="text"
                            value={newProperty.wifi_password}
                            onChange={(e) => setNewProperty({ ...newProperty, wifi_password: e.target.value })}
                        />
                    </div>
                </div>

                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
}

export default AddProperty;
