import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './bookings.css';

function Bookings() {
  const [bookings, setBookings] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [showPastDays, setShowPastDays] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const today = new Date();

  const fetchBookings = useCallback(async (weekStart) => {
    const date = weekStart.toLocaleDateString('en-CA');
    try {
      const response = await axios.get(`/api/bookings/week/${date}`);
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching weekly bookings:', error);
    }
  }, []);

  useEffect(() => {
    fetchBookings(currentWeek);
  }, [currentWeek, fetchBookings]);

  const getMonday = (d) => {
    const date = new Date(d);
    const day = date.getDay();
    const diff = day === 0 ? -6 : 1 - day;
    date.setDate(date.getDate() + diff);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getCurrentWeekDays = () => {
    const startOfWeek = getMonday(currentWeek);
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      day.setHours(0, 0, 0, 0);
      weekDays.push(day);
    }
    return weekDays;
  };

  const goToNextWeek = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(currentWeek.getDate() + 7);
    setCurrentWeek(nextWeek);
  };

  const goToPreviousWeek = () => {
    const prevWeek = new Date(currentWeek);
    prevWeek.setDate(prevWeek.getDate() - 7);
    setCurrentWeek(prevWeek);
  };

  const handleAddBooking = () => {
    navigate('/bookings/new');
  };

  const getAreaColor = (area) => {
    switch (area) {
      case 'Downtown Dubai':
      case 'Business Bay':
        return '#4c7ac5';
      case 'Marina':
      case 'JLT':
      case 'JBR':
        return '#ae2021';
      case 'Palm Jumeirah':
        return '#d9b342';
      case 'Creek Harbour':
        return '#476f37';
      case 'Azizi Riviera':
        return '#a43777';
      default:
        return '#6c757d';
    }
  };

  const getBusiestHourSummary = (dayBookings) => {
    const timeSlots = {};
    dayBookings.forEach((booking) => {
      const startTime = new Date(booking.booking_start);
      const endTime = new Date(booking.booking_end);

      for (let hour = startTime.getHours(); hour < endTime.getHours(); hour++) {
        const slotKey = `${hour}:00 - ${hour + 1}:00`;
        if (!timeSlots[slotKey]) {
          timeSlots[slotKey] = 0;
        }
        timeSlots[slotKey] += booking.required_cleaners;
      }
    });

    let busiestSlot = null;
    let maxCleaners = 0;
    Object.entries(timeSlots).forEach(([slot, cleaners]) => {
      if (cleaners > maxCleaners) {
        maxCleaners = cleaners;
        busiestSlot = slot;
      }
    });

    return { busiestSlot, maxCleaners };
  };

  const weekDays = getCurrentWeekDays();

  const isCurrentWeek = () => {
    const mondayOfCurrentWeek = getMonday(today);
    const sundayOfCurrentWeek = new Date(mondayOfCurrentWeek);
    sundayOfCurrentWeek.setDate(mondayOfCurrentWeek.getDate() + 6);
    return (
      currentWeek >= mondayOfCurrentWeek && currentWeek <= sundayOfCurrentWeek
    );
  };

  return (
    <div className="bookings-container">
      <div className="top-actions">
      <button className="add-booking-button" onClick={handleAddBooking}>
        Add New Booking
      </button>
      <button className="add-booking-button" onClick={() => navigate('/bookings/summary-report')}>
        Booking Summary
      </button>
      </div>
      <div className="top-actions">
        <div className='left-actions'>
          <input
            type="text"
            placeholder="Search by Building, Unit, or Client..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
        </div>
        <div className="navigation">
          {isCurrentWeek() && (
            <button
              className="show-past-days-button"
              onClick={() => setShowPastDays((prev) => !prev)}
            >
              {showPastDays ? 'Hide Earlier Days' : 'Show Earlier Days'}
            </button>
          )}
          <button onClick={goToPreviousWeek}>Previous Week</button>
          <button onClick={goToNextWeek}>Next Week</button>
        </div>
      </div>

      <div className="bookings-grid">
        {weekDays.map((day, index) => {
          const dayStart = new Date(day);
          dayStart.setHours(0, 0, 0, 0);

          const formattedDate = dayStart.toLocaleDateString('en-CA'); // Format for the URL

          const dayEnd = new Date(day);
          dayEnd.setHours(23, 59, 59, 999);

          const dayBookings = bookings.filter((booking) => {
            const bookingDate = new Date(booking.booking_start);
            return bookingDate >= dayStart && bookingDate <= dayEnd;
          });

          // Filter bookings for the summary
          const summaryBookings = dayBookings.filter(
            (booking) =>
              (booking.status_name === 'Confirmed' || booking.status_name === "In Process" || booking.status_name === 'Completed') &&
              booking.booking_type !== 'Laundry Service Only' &&
              booking.booking_type !== 'Delivery' &&
              booking.bookying_type !== 'Pick Up'
          );

          const { maxCleaners } = getBusiestHourSummary(summaryBookings);

          const shouldHideDay = isCurrentWeek() && !showPastDays && dayStart < today.setHours(0, 0, 0, 0);

          if (shouldHideDay) return null;

          // Calculate the busiest period specifically for pending bookings
          const filteredBookings = dayBookings.filter((booking) => {
            const buildingName = booking.building_name || '';
            const unitNumber = booking.unit_number || '';
            const clientDisplayName = booking.client_name || '';

            return (
              buildingName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
              clientDisplayName.toLowerCase().includes(searchTerm.toLowerCase())
            );
          });

          // Replace all instances of dayBookings with filteredBookings
          const pendingBookings = filteredBookings.filter(
            (booking) => booking.status_name === 'Pending'
          );
          const laundryOnlyBookings = filteredBookings.filter(
            (booking) => booking.booking_type === 'Laundry Service Only'
          );
          const deliveryBookings = filteredBookings.filter(
            (booking) => booking.booking_type === 'Delivery'
          );

          const pickupBookings = filteredBookings.filter(
            (booking) => booking.booking_type === 'Pickup'
          );

          const cancelledAndRescheduledBookings = filteredBookings.filter(
            (booking) =>
              booking.status_name === 'Last Minute Cancelled' ||
              booking.status_name === 'Last Minute Rescheduled'
          );
          const otherBookings = filteredBookings.filter(
            (booking) =>
              booking.status_name !== 'Pending' &&
              booking.booking_type !== 'Laundry Service Only' &&
              booking.booking_type !== 'Delivery' &&
              booking.booking_type !== 'Pickup' &&
              booking.status_name !== 'Last Minute Cancelled' &&
              booking.status_name !== 'Last Minute Rescheduled'
          );

          const {
            maxCleaners: maxPendingCleaners,
          } = getBusiestHourSummary(pendingBookings);

          return (
            <div key={index} className="day-row">
              <div className="date-column sticky">
                <h3 className='bookings-date'>
                  <Link to={`/bookings/report/${formattedDate}`} className='date-link'>
                    {day.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short' })}
                  </Link>
                </h3>
                <div className="icon-summary">
                  <span className="icon-item">
                    <FontAwesomeIcon icon={faCalendarAlt} className="minimal-icon" /> {summaryBookings.length}
                  </span>
                  <span className="icon-item">
                    <FontAwesomeIcon icon={faUsers} className="minimal-icon" /> {maxCleaners || 0}
                  </span>
                </div>

                {/* Display the pending bookings summary only if there are any pending bookings */}
                {pendingBookings.length > 0 && (
                  <div className="icon-summary pending-summary">
                    <span className="icon-item">
                      <FontAwesomeIcon icon={faCalendarAlt} className="minimal-icon" /> {pendingBookings.length}
                    </span>
                    <span className="icon-item">
                      <FontAwesomeIcon icon={faUsers} className="minimal-icon" /> {maxPendingCleaners || 0}
                    </span>
                  </div>
                )}
              </div>

              <div className="bookings-columns">
                {pendingBookings.map((booking, i) => {
                  const cleanerNames = booking.cleaner_names || 'No cleaners assigned';
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    opacity: 0.7,
                    cursor: 'pointer',
                  };

                  return (
                    <div
                      key={booking.id}
                      className="booking-box pending-booking"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(booking.booking_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nCleaners: ${cleanerNames}`}
                    >
                      <span className="pending-badge">Pending</span>
                      <p className="booking-number">Request {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                      <p className="booking-time">
                        {new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        - {new Date(booking.booking_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                      <p className="cleaners-required">Cleaners: {booking.required_cleaners}</p>
                    </div>
                  );
                })}

                {pendingBookings.length > 0 && otherBookings.length > 0 && (
                  <div className="separator"></div>
                )}

                {otherBookings.map((booking, i) => {
                  const cleanerNames = booking.cleaner_names || 'No cleaners assigned';
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    cursor: 'pointer',
                  };

                  let badge = null;
                  if (booking.status_name === 'Last Minute Cancelled') {
                    badge = <span className="cancelled-badge">Cancelled</span>;
                  } else if (booking.status_name === 'Last Minute Rescheduled') {
                    badge = <span className="rescheduled-badge">Rescheduled</span>;
                  }

                  return (
                    <div
                      key={booking.id}
                      className="booking-box"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\nBedrooms: ${booking.bedrooms}\nType: ${booking.booking_type}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(booking.booking_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nCleaners: ${cleanerNames}`}
                    >
                      {badge}
                      <p className="booking-number">Booking {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                      <p className="booking-time">
                        {new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        - {new Date(booking.booking_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                      <p className="cleaners-required">Cleaners: {booking.required_cleaners}</p>
                    </div>
                  );
                })}

                {laundryOnlyBookings.length > 0 && (
                  <div className="separator"></div>
                )}

                {laundryOnlyBookings.map((booking, i) => {
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    opacity: 0.7,
                    cursor: 'pointer',
                  };

                  return (
                    <div
                      key={booking.id}
                      className="booking-box laundry-booking"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\nBedrooms: ${booking.bedrooms}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nLaundry Service Only`}
                    >
                      <span className="laundry-badge">Laundry Only</span>
                      <p className="booking-number">Laundry {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                    </div>
                  );
                })}

                {deliveryBookings.length > 0 && (
                  <div className="separator"></div>
                )}

                {deliveryBookings.map((booking, i) => {
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    opacity: 0.7,
                    cursor: 'pointer',
                  };

                  return (
                    <div
                      key={booking.id}
                      className="booking-box delivery-booking"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\nBedrooms: ${booking.bedrooms}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nDelivery`}
                    >
                      <span className="delivery-badge">Delivery</span>
                      <p className="booking-number">Delivery {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                    </div>
                  );
                })}

                {pickupBookings.length > 0 && (
                  <div className="separator"></div>
                )}

                {pickupBookings.map((booking, i) => {
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    opacity: 0.7,
                    cursor: 'pointer',
                  };

                  return (
                    <div
                      key={booking.id}
                      className="booking-box pickup-booking"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\nBedrooms: ${booking.bedrooms}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nPickup`}
                    >
                      <span className="pickup-badge">Pickup</span>
                      <p className="booking-number">Pickup {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                    </div>
                  );
                })}


                {cancelledAndRescheduledBookings.length > 0 && (
                  <div className="separator"></div>
                )}

                {cancelledAndRescheduledBookings.map((booking, i) => {
                  const bookingStyles = {
                    backgroundColor: getAreaColor(booking.area),
                    opacity: 0.7,
                    cursor: 'pointer',
                  };

                  const badgeText =
                    booking.status_name === 'Last Minute Cancelled'
                      ? 'Cancelled'
                      : 'Rescheduled';

                  const badgeClass =
                    booking.status_name === 'Last Minute Cancelled'
                      ? 'cancelled-badge'
                      : 'rescheduled-badge';

                  return (
                    <div
                      key={booking.id}
                      className="booking-box cancelled-rescheduled-booking"
                      style={bookingStyles}
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      data-tooltip-id={`booking-${booking.id}`}
                      data-tooltip-content={`Client: ${booking.client_name}\nUnit: ${booking.unit_number} - ${booking.building_name}\nBedrooms: ${booking.bedrooms}\n\nTime: ${new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\n${badgeText}`}
                    >
                      <span className={badgeClass}>{badgeText}</span>
                      <p className="booking-number">Cancellation {i + 1}</p>
                      <p className="property-info">
                        <strong>{booking.unit_number} - {booking.building_name}</strong>
                      </p>
                      <p className="client-name">{booking.client_name}</p>
                      <p className="booking-time">
                        {new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Tooltip anchorSelect="[data-tooltip-id]" place="top" className="custom-tooltip" />
    </div>
  );
}

export default Bookings;
