import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import LoadingPopup from '../../LoadingPopup';

function Notes({ property, propertyId, updateNotes }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenWarehouse, setModalIsOpenWarehouse] = useState(false);
  const [modalIsOpenScheduling, setModalIsOpenScheduling] = useState(false);
  const [modalIsOpenPermanent, setModalIsOpenPermanent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  
  const [disappearingNote, setDisappearingNote] = useState(property.dissapearing_note || '');
  const [warehouseNote, setWarehouseNote] = useState(property.warehouse_note || '');
  const [schedulingNote, setSchedulingNote] = useState(property.scheduling_note || '');
  const [permanentNotes, setPermanentNotes] = useState(property.permanent_notes || '');

  const handleNoteUpdate = async (url, data, closeModal) => {
    setIsLoading(true);
    try {
      const response = await axios.put(url, data);
      if (response.status === 200) {
        updateNotes(response.data);
        setFeedbackMessage('Note updated successfully!');
      }
    } catch (error) {
      setFeedbackMessage('Error updating note.');
      console.error('Error updating note:', error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <div className="details-card notes-section full-width">
      <h2>Notes</h2>
      <div className="notes-row">
        <div className="note-item">
          <span className="label">Permanent Notes:</span>
          <span className="value">{property.permanent_notes}</span>
        </div>
        <button className="add-note-button" onClick={() => setModalIsOpenPermanent(true)}>
          + Update Permanent Notes
        </button>
        <div className="note-item">
          <span className="label">Notes for next cleaning:</span>
          <span className="value">{property.dissapearing_note}</span>
        </div>
        <button className="add-note-button" onClick={() => setModalIsOpen(true)}>
          + Update Notes for Next Cleaning
        </button>
        <div className="note-item">
          <span className="label">Permanent Warehouse Notes:</span>
          <span className="value">{property.warehouse_note}</span>
        </div>
        <button className="add-note-button" onClick={() => setModalIsOpenWarehouse(true)}>
          + Update Warehouse Notes
        </button>
        <div className="note-item">
          <span className="label">Scheduling Notes:</span>
          <span className="value">{property.scheduling_note}</span>
        </div>
        <button className="add-note-button" onClick={() => setModalIsOpenScheduling(true)}>
          + Update Scheduling Notes
        </button>
      </div>

      <LoadingPopup isLoading={isLoading} feedbackMessage={feedbackMessage} onClose={() => setFeedbackMessage('')} />

      {/* Modal for Permanent Note */}
      <Modal isOpen={modalIsOpenPermanent} onRequestClose={() => setModalIsOpenPermanent(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpenPermanent(false)}>&times;</button>
        <h2>Update Permanent Note</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleNoteUpdate(`/api/properties/${propertyId}/permanent-note`, { permanent_notes: permanentNotes || null }, () => setModalIsOpenPermanent(false)); }}>
          <textarea value={permanentNotes} onChange={(e) => setPermanentNotes(e.target.value)} placeholder="Enter permanent note" />
          <button type="submit">Submit</button>
        </form>
      </Modal>

      {/* Other Modals */}
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpen(false)}>&times;</button>
        <h2>Update Note for Next Cleaning</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleNoteUpdate(`/api/properties/${propertyId}/note`, { dissapearing_note: disappearingNote || null }, () => setModalIsOpen(false)); }}>
          <textarea value={disappearingNote} onChange={(e) => setDisappearingNote(e.target.value)} placeholder="Enter note for next cleaning" />
          <button type="submit">Submit</button>
        </form>
      </Modal>

      <Modal isOpen={modalIsOpenWarehouse} onRequestClose={() => setModalIsOpenWarehouse(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpenWarehouse(false)}>&times;</button>
        <h2>Update Warehouse Note</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleNoteUpdate(`/api/properties/${propertyId}/warehouse-note`, { warehouse_note: warehouseNote || null }, () => setModalIsOpenWarehouse(false)); }}>
          <textarea value={warehouseNote} onChange={(e) => setWarehouseNote(e.target.value)} placeholder="Enter warehouse note" />
          <button type="submit">Submit</button>
        </form>
      </Modal>

      <Modal isOpen={modalIsOpenScheduling} onRequestClose={() => setModalIsOpenScheduling(false)} className="modal-content" overlayClassName="modal-overlay">
        <button className="close-button" onClick={() => setModalIsOpenScheduling(false)}>&times;</button>
        <h2>Update Scheduling Note</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleNoteUpdate(`/api/properties/${propertyId}/scheduling-note`, { scheduling_note: schedulingNote || null }, () => setModalIsOpenScheduling(false)); }}>
          <textarea value={schedulingNote} onChange={(e) => setSchedulingNote(e.target.value)} placeholder="Enter scheduling note" />
          <button type="submit">Submit</button>
        </form>
      </Modal>
    </div>
  );
}

export default Notes;
