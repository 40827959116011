import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./clientBookingDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import ClientBookingReports from "./ClientBookingReports";

const ClientBookingDetails = () => {
    const { bookingId } = useParams();
    const [booking, setBooking] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`/api/client-requests/bookings/${bookingId}`);
                setBooking(response.data);
            } catch (err) {
                setError("Failed to fetch booking details.");
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [bookingId]);

    if (loading) return <div className="client-loading">Loading booking details...</div>;
    if (error) return <div className="client-error-message">{error}</div>;

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    };

    return (
        <div className="client-booking-card">
            <h2>Booking #{booking.booking_id}</h2>
            <h3 className="client-booking-subtitle">
                <FontAwesomeIcon icon={faBuilding} /> Unit {booking.unit_number} - {booking.building_name}
            </h3>

            <div className="client-section">
                <h3>General Booking Details</h3>
                <p><strong>Start Time:</strong> {formatDate(booking.booking_start)}</p>
                <p><strong>Duration:</strong> {booking.duration_hours} hours</p>
                <p><strong>Type of Cleaning:</strong> {booking.booking_type}</p>
                <p><strong>Status:</strong> {booking.booking_status}</p>
            </div>

            <div className="client-section">
                <h3>Booking Notes</h3>
                <p><FontAwesomeIcon icon={faStickyNote} /> <strong>Permanent Notes:</strong></p>
                <pre className='permanent-notes client-booking-notes'>
                    {booking.property_permanent_notes}
                    {booking.property_permanent_notes && booking.client_permanent_notes ? '\n\n' : ''}
                    {booking.client_permanent_notes}
                    {!booking.property_permanent_notes && !booking.client_permanent_notes ? "No notes available" : ""}
                </pre>
                <p><FontAwesomeIcon icon={faStickyNote} /> <strong>Notes for this Booking:</strong></p><p className="client-booking-notes">{booking.booking_specific_notes || "No notes available"}</p>
            </div>
            <div className="client-section">
                <h3>Booking Reports</h3>
                <ClientBookingReports bookingId={bookingId} />
            </div>
        </div>
    );
};

export default ClientBookingDetails;