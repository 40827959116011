import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import GeneralDetails from './PropertyDetailsComponents/GeneralDetails';
import AddressInformation from './PropertyDetailsComponents/AddressInformation';
import PropertyDetailsSection from './PropertyDetailsComponents/PropertyDetailsSection';
import AdditionalInfo from './PropertyDetailsComponents/AdditionalInfo';
import Notes from './PropertyDetailsComponents/Notes';
import ApartmentSetup from './PropertyDetailsComponents/ApartmentSetup';
import BookingsList from './PropertyDetailsComponents/BookingsList';
import axios from 'axios';
import './propertyDetails.css';

function PropertyDetails() {
  const { propertyId } = useParams();
  const [property, setProperty] = useState(null);
  const [setupPhotos, setSetupPhotos] = useState([]);
  const navigate = useNavigate();

  const getAreaColor = (area) => {
    switch (area) {
      case 'Downtown Dubai':
      case 'Business Bay':
        return '#4c7ac5'; // Blue
      case 'Marina':
      case 'JLT':
      case 'JBR':
        return '#ae2021'; // Red
      case 'Palm Jumeirah':
        return '#d9b342'; // Gold
      case 'Creek Harbour':
        return '#476f37'; // Green
      case 'Azizi Riviera':
        return '#a43777'; // Magenta
      default:
        return '#6c757d'; // Grey
    }
  };

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`/api/properties/${propertyId}`);
        setProperty(response.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    fetchProperty();
  }, [propertyId]);

  useEffect(() => {
    const fetchSetupPhotos = async () => {
      try {
        const response = await axios.get(`/api/properties/${propertyId}/setup-photos`);
        setSetupPhotos(response.data);
      } catch (error) {
        console.error('Error fetching setup photos:', error);
      }
    };

    fetchSetupPhotos();
  }, [propertyId]);

  const updateNotes = (newNotes) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      permanent_notes: newNotes.permanent_notes,
      dissapearing_note: newNotes.dissapearing_note,
      warehouse_note: newNotes.warehouse_note,
      scheduling_note: newNotes.scheduling_note,
    }));
  };

  if (!property) {
    return <div>Loading...</div>;
  }

  const bedroomDisplay = property.bedrooms === 0 ? 'S' : property.bedrooms;
  const propertyDisplay = `${property.client_display_name} - ${property.address_line_1} - Unit ${property.unit_number} (${bedroomDisplay} - ${property.bathrooms})`;

  const areaColor = getAreaColor(property.area);

  return (
    <div className="property-details-wrapper">
      <div className="header">
        <div className="header-title">
          <h1>Property ID: #{propertyId.toString().padStart(6, '0')}</h1>
          <h2 className="unit-display" style={{ color: areaColor }}>
            {propertyDisplay}
          </h2>
        </div>
        <button className="edit-button" onClick={() => navigate(`/properties/edit/${propertyId}`)}>
          Edit Property
        </button>
      </div>

      <div className="property-details-container">
        <GeneralDetails property={property} />
        <AddressInformation property={property} />
        <PropertyDetailsSection property={property} />
        <AdditionalInfo property={property} />
        <Notes property={property} propertyId={propertyId} updateNotes={updateNotes} />
        <ApartmentSetup setupPhotos={setupPhotos} propertyId={propertyId} />
      </div>

      {/* Include the BookingsList component */}
      <BookingsList propertyId={propertyId} />
    </div>
  );
}

export default PropertyDetails;
