import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './clientHome.css';

function ClientHome() {
  const [firstName, setFirstName] = useState('');
  const [clientId, setClientId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch client-specific details from localStorage
    const storedFirstName = localStorage.getItem('firstName');
    const userRole = localStorage.getItem('userRole');
    const storedClientId = localStorage.getItem('clientId');

    setFirstName(storedFirstName);
    setClientId(storedClientId);

    // Redirect non-clients to their appropriate dashboard
    if (userRole !== 'client') {
      navigate('/');
      return;
    }
  }, [navigate]);

  return (
    <div className="client-home-dashboard">
      <h1>Welcome {firstName ? firstName : 'Client'}! Your portal is ready.</h1>
      <p>Manage your properties, bookings, and invoices effortlessly.</p>
      <p>Your client id is {clientId}</p>
    </div>
  );
}

export default ClientHome;
