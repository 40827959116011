import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from '../../LoadingPopup';
import './bookingReports.css';

const reportOptions = [
    { value: 'before_cleaning_video', label: 'Before Cleaning Video' },
    { value: 'after_cleaning_video', label: 'After Cleaning Video' },
    { value: 'stained_laundry_pickup', label: 'Stained Laundry Pickup' },
    { value: 'damages_apartment_condition', label: 'Damages / Apartment Condition' }
];

function BookingReports({ bookingId }) {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingReportId, setEditingReportId] = useState(null);
    const [editedText, setEditedText] = useState({ type: "", area: "", comment: "" });
    const [isProcessing, setIsProcessing] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const fetchReports = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/booking-reports/${bookingId}`);
            if (response.status === 200 && Array.isArray(response.data)) {
                setReports(response.data);
            } else {
                setReports([]); // Ensure it's an empty array if no reports exist
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setReports([]); // No reports for this booking, show empty state
            } else {
                console.error('Error fetching reports:', error);
                setError('Error fetching reports.');
            }
        } finally {
            setLoading(false);
        }
    }, [bookingId]);


    useEffect(() => {
        fetchReports();
    }, [fetchReports]); // Dependency array includes fetchReports

    const formatType = (type) => {
        const foundType = reportOptions.find(option => option.value === type);
        return foundType ? foundType.label : type.replace(/_/g, ' ');
    };

    const openModal = (report) => {
        setModalContent(report);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleEdit = (report) => {
        if (editingReportId === report.id) {
            setEditingReportId(null);
        } else {
            setEditingReportId(report.id);
            setEditedText({
                type: report.type || "",
                area: report.area || "",
                comment: report.comment || "",
                is_approved: report.is_approved || ""
            });
        }
    };

    const handleSave = async (reportId) => {
        setIsProcessing(true);
        setFeedbackMessage("Saving report...");
        try {
            await axios.put(`/api/booking-reports/${reportId}`, editedText);
            setReports((prevReports) =>
                prevReports.map((report) =>
                    report.id === reportId ? { ...report, ...editedText } : report
                )
            );
            setEditingReportId(null);
            setFeedbackMessage("Report updated successfully.");
        } catch (error) {
            console.error("Error updating report:", error);
            setFeedbackMessage("Failed to update report.");
        } finally {
            setIsProcessing(false);
        }
    };

    const handleDelete = async (reportId) => {
        if (!window.confirm("Are you sure you want to delete this report? This action cannot be undone.")) {
            return;
        }

        setIsProcessing(true);
        setFeedbackMessage("Deleting report...");
        try {
            await axios.delete(`/api/booking-reports/${reportId}`);
            setReports(reports.filter((report) => report.id !== reportId));
            setFeedbackMessage("Report deleted successfully.");
        } catch (error) {
            console.error("Error deleting report:", error);
            setFeedbackMessage("Failed to delete report.");
        } finally {
            setIsProcessing(false);
        }
    };

    if (error) return <div>{error}</div>;

    return (
        <div className="booking-reports-container">
            <p className="booking-30-days">Please note attachments are only available for 30 days from date of upload.</p>
            <div className="booking-reports-grid">
                {reports.map((report) => (
                    <div key={report.id} className="booking-report-card">


                        <div className="booking-report-thumbnail" onClick={() => openModal(report)}>
                            {/\.(mp4|mov|avi|mkv)$/i.test(report.file_url) ? (
                                <video className="booking-report-video-preview">
                                    <source src={report.file_url} type="video/mp4" />
                                </video>
                            ) : (
                                <img src={report.file_url} alt="Report Attachment" className="booking-report-image-preview" />
                            )}
                        </div>

                        <div className="booking-report-header">
                            {editingReportId === report.id ? (
                                <Select
                                    className="report-edit-select"
                                    options={reportOptions}
                                    value={reportOptions.find(option => option.value === editedText.type)}
                                    onChange={(selectedOption) => setEditedText({ ...editedText, type: selectedOption.value })}
                                />
                            ) : (
                                <p className="booking-report-type">{formatType(report.type)}</p>
                            )}
                            {editingReportId === report.id ?
                                <FontAwesomeIcon
                                    icon= {faTimes}
                                    className="report-cancel-icon"
                                    onClick={() => handleEdit(report)}
                                /> :
                                <FontAwesomeIcon
                                    icon= {faPen}
                                    className="report-edit-icon"
                                    onClick={() => handleEdit(report)}
                                />
                            }
                        </div>

                        {editingReportId === report.id ? (
                            <div className="report-edit-mode">
                                <input
                                    type="text"
                                    className="report-edit-input"
                                    placeholder="Area"
                                    value={editedText.area}
                                    onChange={(e) => setEditedText({ ...editedText, area: e.target.value })}
                                />
                                <input
                                    type="text"
                                    className="report-edit-input"
                                    placeholder="Comment"
                                    value={editedText.comment}
                                    onChange={(e) => setEditedText({ ...editedText, comment: e.target.value })}
                                />
                                <label className="report-edit-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={editedText.is_approved}
                                        onChange={(e) => setEditedText({ ...editedText, is_approved: e.target.checked })}
                                    />
                                    Approved
                                </label>
                                <div className="report-edit-buttons">
                                    <button className="report-delete-button" onClick={() => handleDelete(report.id)}>Delete</button>
                                    <button className="report-save-button" onClick={() => handleSave(report.id)}>Save</button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p className="booking-report-date"><strong>{new Date(report.uploaded_at).toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</strong></p>
                                <p className="booking-report-comment"><strong>Comment:</strong> {report.comment || "No comment"}</p>
                                <p className="booking-report-area"><strong>Area:</strong> {report.area || "N/A"}</p>
                                <p className="booking-report-comment">
                                    <strong>Approved:</strong>
                                    <span className={report.is_approved ? 'approved-text' : 'not-approved-text'}>
                                        {report.is_approved ? " Yes" : " No"}
                                    </span>
                                </p>
                            </>
                        )}
                    </div>
                ))}
            </div>

            {/* Full-Size Media Modal */}
            {isModalOpen && modalContent && (
                <div className="booking-report-modal">
                    <div className="booking-report-modal-content">
                        <span className="popup-close" onClick={closeModal}>&times;</span>
                        {/\.(mp4|mov|avi|mkv)$/i.test(modalContent.file_url) ? (
                            <video controls className="booking-report-full-video" autoPlay>
                                <source src={modalContent.file_url} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={modalContent.file_url} alt="Full Report Attachment" className="booking-report-full-image" />
                        )}
                        <div className="booking-report-modal-info">
                            <p><strong>{new Date(modalContent.uploaded_at).toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</strong></p>
                            <p><strong>Report Type:</strong> {formatType(modalContent.type)}</p>
                            <p><strong>Comment:</strong> {modalContent.comment || "N/A"}</p>
                            <p><strong>Area:</strong> {modalContent.area || "N/A"}</p>
                        </div>
                    </div>
                </div>
            )}
            <LoadingPopup isLoading={loading || isProcessing} feedbackMessage={feedbackMessage} onClose={() => setFeedbackMessage("")} />
        </div>

    );
}

export default BookingReports;
