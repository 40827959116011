import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import LoadingPopup from '../../LoadingPopup';
import './clientBookings.css'

function ClientBookings() {
  const [bookings, setBookings] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({ value: null, label: 'All Properties' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [view, setView] = useState('future'); // 'future' or 'past'
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchProperties = useCallback(async () => {
    try {
      const response = await axios.get('/api/client-requests/properties/');
      const formattedProperties = response.data.properties.map((property) => ({
        value: property.property_id,
        label: `Unit ${property.unit_number} - ${property.building_name}`,
      }));
      setProperties([{ value: null, label: 'All Properties' }, ...formattedProperties]);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  }, []);

  const fetchBookings = useCallback(async () => {
    setIsLoading(true);
    try {
      let endpoint = `/api/client-requests/bookings/all/${view}?page=${currentPage}`;
      if (selectedProperty?.value !== null) {
        endpoint += `&property_id=${selectedProperty.value}`;
      }
      const response = await axios.get(endpoint);
      setBookings(response.data.bookings);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error(`Error fetching ${view} bookings:`, error);
    }
    setIsLoading(false);
  }, [view, currentPage, selectedProperty]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
      case 'confirmed':
        return 'green';
      case 'cancelled':
      case 'rescheduled':
        return 'red';
      case 'pending':
      case 'in_progress':
        return 'orange';
      default:
        return 'grey';
    }
  };

  return (
    <div className="bookings-section">
      <LoadingPopup isLoading={isLoading} />
      <div className="view-toggle">
        <button
          className={`toggle-button ${view === 'future' ? 'active' : ''}`}
          onClick={() => { setView('future'); setCurrentPage(1); }}
        >
          Future Bookings
        </button>
        <button
          className={`toggle-button ${view === 'past' ? 'active' : ''}`}
          onClick={() => { setView('past'); setCurrentPage(1); }}
        >
          Past Bookings
        </button>
      </div>
      <div className="client-bookings-dropdown ">
        <Select
          options={properties}
          value={selectedProperty}
          onChange={(selected) => {
            setSelectedProperty(selected);
            setCurrentPage(1);
          }}
          placeholder="Filter by Property"
        />
      </div>
      <div className="bookings-list">
        {bookings.map((booking) => {
          const bookingDate = new Date(booking.booking_start);
          const day = bookingDate.getDate();
          const month = bookingDate.toLocaleString('default', { month: 'short' });
          const year = bookingDate.getFullYear();
          const time = bookingDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

          return (
            <div
              className="booking-card"
              key={booking.booking_id}
              onClick={() => navigate(`/client/bookings/${booking.booking_id}`)}
            >
              <div className="booking-date">
                <div className="date-day">{day}</div>
                <div className="date-month-year">
                  <span>{month}</span>
                  <span>{year}</span>
                </div>
              </div>
              <div className="booking-details-box">
                <p><strong>Property:</strong> Unit {booking.unit_number} - {booking.building_name}</p>
                <p><strong>Booking ID:</strong> #{booking.booking_id}</p>
                <p><strong>Type:</strong> {booking.booking_type}</p>
                <p>
                  <strong>Status:</strong>{' '}
                  <span style={{ color: getStatusColor(booking.booking_status) }}>
                    {booking.booking_status}
                  </span>
                </p>
                <p><strong>Time:</strong> {time}</p>
                <p><strong>Duration:</strong> {booking.duration_hours} hrs</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pagination-controls">
        <button
          className="pagination-button"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="pagination-button"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ClientBookings;
