import React from 'react';

function NotesSection({ formData, setFormData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='notes'>
      <div className="form-group">
        <label>Booking Notes</label>
        <textarea
          name="booking_specific_notes"
          value={formData.booking_specific_notes || ""}
          onChange={handleChange}
          placeholder="Add any specific notes for this booking"
          className="notes-textarea"
        ></textarea>
      </div>

      <div className="form-group">
        <label>Warehouse Notes</label>
        <textarea
          name="booking_warehouse_notes"
          value={formData.booking_warehouse_notes || ""}
          onChange={handleChange}
          placeholder="Add any warehouse notes for this booking"
          className="notes-textarea"
        ></textarea>
      </div>
    </div>
  );
}

export default NotesSection;
