import React, { useEffect, useState } from "react";
import axios from "axios";
import "./clientBookingReports.css";

const ClientBookingReports = ({ bookingId }) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [noReports, setNoReports] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);

    // Function to format report type into human-readable format
    const formatReportType = (type) => {
        const typeMappings = {
            before_cleaning_video: "Before Cleaning Video",
            after_cleaning_video: "After Cleaning Video",
            damages_apartment_condition: "Damage / Unit Condition",
            stained_laundry_pickup: "Stained Laundry Pickup"
        };
        return typeMappings[type] || "Other Report";
    };

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await axios.get(`/api/client-requests/bookings/${bookingId}/reports`);
                if (response.data.length === 0) {
                    setNoReports(true);
                } else {
                    setReports(response.data);
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setNoReports(true);
                } else {
                    setError("Failed to fetch booking reports.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchReports();
    }, [bookingId]);

    const handleMediaOpen = (report) => {
        setSelectedMedia(report.file_url);
        setSelectedReport(report);
        setTimeout(() => {
            const video = document.querySelector(".client-report-full-video");
            if (video) {
                video.play();
            }
        }, 100); // Delay to ensure modal is fully rendered before playing
    };

    const handleClose = () => {
        setSelectedMedia(null);
        setSelectedReport(null);
    };

    if (loading) return <div className="client-loading">Loading reports...</div>;
    if (error) return <div className="client-error-message">{error}</div>;
    if (noReports) return <p className="client-no-reports">No reports available</p>;

    return (
        <div className="client-reports-container">
            <p className="client-30-days">Please note attachments are only available for 30 days from date of upload.</p>
            <div className="client-reports-grid">
                {reports.map((report) => (
                    <div key={report.id} className="client-report-card" onClick={() => handleMediaOpen(report)}>
                        <div className="client-report-thumbnail">
                            {/\.(mp4|mov|avi|mkv)$/i.test(report.file_url) ? (
                                <video className="client-report-video-preview">
                                    <source src={report.file_url} type="video/mp4" />
                                </video>
                            ) : (
                                <img src={report.file_url} alt="Report Attachment" className="client-report-image-preview" />
                            )}
                        </div>
                        <p className="client-report-type">{formatReportType(report.type)}</p>
                        <p className="client-report-date"><strong>{new Date(report.uploaded_at).toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</strong></p>
                        <p className="client-report-comment"><strong>Comment:</strong> {report.comment || "No comment"}</p>
                        <p className="client-report-area"><strong>Area:</strong> {report.area || "N/A"}</p>
                    </div>
                ))}
            </div>

            {/* Full-Size Media Modal */}
            {selectedMedia && (
                <div className="client-report-modal">
                    <div className="client-report-modal-content">
                        <span className="popup-close" onClick={handleClose}>&times;</span>
                        {/\.(mp4|mov|avi|mkv)$/i.test(selectedMedia) ? (
                            <video controls className="client-report-full-video" autoPlay>
                                <source src={selectedMedia} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={selectedMedia} alt="Full Report Attachment" className="client-report-full-image" />
                        )}
                        <div className="client-report-modal-info">
                            <p><strong>{new Date(selectedReport?.uploaded_at).toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</strong></p>
                            <p><strong>Report Type:</strong> {formatReportType(selectedReport?.type)}</p>
                            <p><strong>Comment:</strong> {selectedReport?.comment || "N/A"}</p>
                            <p><strong>Area:</strong> {selectedReport?.area || "N/A"}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientBookingReports;
