import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import './addReportModal.css';
import LoadingPopup from '../../LoadingPopup';

const reportOptions = [
    { value: 'before_cleaning_video', label: 'Before Cleaning Video' },
    { value: 'after_cleaning_video', label: 'After Cleaning Video' },
    { value: 'stained_laundry_pickup', label: 'Stained Laundry Pickup' },
    { value: 'damages_apartment_condition', label: 'Damages / Apartment Condition' }
];

function AddReportModal({ bookingId, onClose }) {
    const [selectedType, setSelectedType] = useState(null);
    const [area, setArea] = useState('');
    const [comment, setComment] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedType) return alert("Please select a report type.");
        if ((selectedType.value === 'stained_laundry_pickup' || selectedType.value === 'damages_apartment_condition') && !comment) {
            return alert("Comment is required for this type of report.");
        }

        const formData = new FormData();
        formData.append("booking_id", bookingId);
        formData.append("type", selectedType.value);
        formData.append("is_approved", false); // Default false as per schema

        if (selectedType.value === 'damages_apartment_condition') {
            formData.append("area", area);
        }
        if (comment) formData.append("comment", comment);
        if (file) formData.append("file", file);

        try {
            setLoading(true);
            await axios.post("/api/booking-reports", formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setFeedbackMessage("Report added successfully!");
        } catch (error) {
            console.error("Error adding report:", error);
            setFeedbackMessage("Failed to add report.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="add-report-popup">
                <span className="popup-close" onClick={onClose}>&times;</span>
                <h2>Add Report</h2>
                <form onSubmit={handleSubmit} className="booking-report-form">
                    <div className="form-group">
                        <label>Report Type:</label>
                        <Select
                            options={reportOptions}
                            value={selectedType}
                            onChange={setSelectedType}
                            placeholder="Select report type..."
                        />
                    </div>
                    
                    {selectedType?.value === 'damages_apartment_condition' && (
                        <div className="form-group">
                            <label>Area:</label>
                            <input 
                                type="text" 
                                value={area} 
                                onChange={(e) => setArea(e.target.value)} 
                                placeholder="Enter area (e.g., Bedroom)" 
                                className="input-field" 
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label>Comment:</label>
                        <textarea 
                            value={comment} 
                            onChange={(e) => setComment(e.target.value)} 
                            placeholder="Enter comment..." 
                            required={selectedType?.value === 'stained_laundry_pickup' || selectedType?.value === 'damages_apartment_condition'} 
                            className="textarea-field" 
                        />
                    </div>

                    <div className="form-group">
                        <label>Attach File (Image/Video):</label>
                        <input type="file" accept="image/*,video/*" onChange={handleFileChange} className="file-input" />
                    </div>

                    <button type="submit" disabled={loading} className="submit-button">
                        {loading ? "Uploading..." : "Submit Report"}
                    </button>
                </form>

                <LoadingPopup
                    isLoading={loading}
                    feedbackMessage={feedbackMessage}
                    onClose={() => {
                        setFeedbackMessage('');
                        if (feedbackMessage === 'Report added successfully!') {
                            onClose();
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default AddReportModal;
